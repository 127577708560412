import React from 'react'
import Header from '../components/Header'
import HomeBanner from '../components/Home.js/HomeBanner'
import HomeContent2 from '../components/Home.js/HomeContent2'
import HomeContent3 from '../components/Home.js/HomeContent3'
import HomeContent4 from '../components/Home.js/HomeContent4'
import Footer from '../components/Footer'
import HomeSection3 from '../components/Home.js/HomeSection3'
const Home = () => {
  return (
    <div>
        <Header/>
        <HomeBanner/>
        <HomeContent2/>
        <HomeSection3/>
        {/* <HomeContent3/> */}
        {/* <HomeContent4/> */}
        <Footer/>


        </div>
  )
}

export default Home
