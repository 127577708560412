import React from "react";
import image from '../../img/gaza.avif'
const HomeSection3 = () => {
  return (
    <div className="container-fluid bg-danger py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6 col-12 py-3 px-3">
            <div class="section-header">
              <p>SITUATION</p>
              <h2 className="text-dark">GAZA EMERGENCY SITUATION UPDATES</h2>
            </div>
            <p className="text-light">
              <strong style={{fontSize:'22px'}}>
              MAP is responding to an unprecedented emergency in Gaza as a
              result of Israel's bombardment and siege. Read the latest
              humanitarian situation updates here.
              </strong>
            </p>
          </div>
          <div className="col-md-12 col-lg-6 col-12">
            <img src={image} alt="demo" className="about-img"  width={'100%'} style={{height:'400px'}}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection3;
