import React from "react";
import img1 from "../../img/gaza2.webp";
const HomeContent2 = () => {
  return (
    <div>
      <div>
        {/* Video Modal Start*/}
      
        {/* Video Modal End */}
        {/* About Start */}
        <div className="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                {/* <div className="about-img" data-parallax="scroll" data-image-src={img1} /> */}
                <img className="about-img" alt="asa" style={{height:'400px'}}  data-parallax="scroll" src={img1} />
              </div>
              <div className="col-lg-6">
                <div className="section-header">
                  <p>Learn About Us</p>
                  <h2>Protect PalestinianS IN GAZA FROM ATROCITIES</h2>
                </div>
                {/* <div className="about-tab">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="pill" href="#tab-content-1">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="pill" href="#tab-content-2">Mission</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="pill" href="#tab-content-3">Vision</a>
              </li>
            </ul>
            <div className="tab-content">
              <div id="tab-content-1" className="container tab-pane active">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae pellentesque turpis. Donec in hendrerit dui, vel blandit massa. Ut vestibulum suscipit cursus. Cras quis porta nulla, ut placerat risus. Aliquam nec magna eget velit luctus dictum. Phasellus et felis sed purus tristique dignissim. Morbi sit amet leo at purus accumsan pellentesque. Vivamus fermentum nisi vel dapibus blandit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
              <div id="tab-content-2" className="container tab-pane fade">
                Sed tincidunt, magna ut vehicula volutpat, turpis diam condimentum justo, posuere congue turpis massa in mi. Proin ornare at massa at fermentum. Nunc aliquet sed nisi iaculis ornare. Nam semper tortor eget est egestas, eu sagittis nunc sodales. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent bibendum sapien sed purus molestie malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
              <div id="tab-content-3" className="container tab-pane fade">
                Aliquam dolor odio, mollis sed feugiat sit amet, feugiat ut sapien. Nunc eu dignissim lorem. Suspendisse at hendrerit enim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed condimentum semper turpis vel facilisis. Nunc vel faucibus orci. Mauris ut mauris rhoncus, efficitur nisi at, venenatis quam. Praesent egestas pretium enim sit amet finibus. Curabitur at erat molestie, tincidunt lorem eget, consequat ligula.
              </div>
            </div>
          </div> */}
                <p>
                  Medical Aid for Palestinians (MAP) works for the health and
                  dignity of Palestinians living under occupation and as
                  refugees.
                </p>
                <p>
                  We provide immediate medical aid to those in great need, while
                  also developing local capacity and skills to ensure the
                  long-term development of the Palestinian healthcare system.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
      </div>
    </div>
  );
};

export default HomeContent2;
