import React from "react";
import DonateBanner from "../components/Donate.js/DonateBanner";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DonateNow from "../components/Donate.js/DonateNow";
const Doante = () => {
  return (
    <div>
      <Header />
      <DonateBanner />
      <DonateNow />
      <Footer />
    </div>
  );
};

export default Doante;
