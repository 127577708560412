import React, { useState } from "react";
import img1 from "../../img/selab/selab4.jpeg";
import img2 from "../../img/selab/selab2.jpeg";
import img3 from "../../img/selab/selab3.jpeg";

import { Carousel, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
const HomeBanner = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className="banner-home d-flex justify-content-center align-items-center ">
        <div className="container ">
          <div className="row ">
            <div className="col">
              <h1 style={{ fontSize: "52px" }}>
                <span className="bg-white text-dark   px-2 ">
                  GAZA EMERGENCY
                </span>
              </h1>
              <h2 style={{ fontSize: "24px",marginTop:'20px' }}>
                <span className="bg-danger text-light px-2 py-1">
                PLEASE SUPPORT OUR   URGENT RESPONSE
                </span>
              </h2>
              <a href="/Donate" className="btn btn-light text-dark " style={{ fontSize: "18px",marginTop:'10px' }}>
                <b>Donate Now</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
