import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../img/Logo.png";

const Header = () => {
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  const [show, setShow] = useState(false);

  const handleMediaQueryChange2 = () => {
    setShow(!show);
    //   if(setShow(true)){
    //     var element = document.getElementById("navbarmenu");
    // element.classList.add("show");

    //   }else{
    //     setShow(false)
    //   }
  };
  const checkActive = (match, location) => {
    //some additional logic to verify you are in the home URI
    if (!location) return false;
    const { pathname } = location;
    console.log(pathname);
    return pathname === "/";
  };
  return (
    <div>
      <div>
        {/* Top Bar Start */}
        <div className="top-bar d-none d-md-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <div className="top-bar-left">
                  {/* <div className="text">
              <i className="fa fa-phone-alt" />
              <p>+123 456 7890</p>
            </div> */}
                  <div className="text">
                    <i className="fa fa-envelope" />
                    <p>admin@gaza.org</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="top-bar-right">
                  <div className="social">
                    <a href>
                      <i className="fab fa-twitter" />
                    </a>
                    <a href>
                      <i className="fab fa-facebook-f" />
                    </a>
                    {/* <a href><i className="fab fa-linkedin-in" /></a> */}
                    <a href>
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Top Bar End */}
        {/* Nav Bar Start */}

        <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <div class="container-fluid">
            <a class="navbar-brand" href="/">
              <img src={Logo} alt="logo" className="img-fluid" width={"100%"}></img>
            </a>
            <button
              onClick={() => handleMediaQueryChange2()}
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class={
                show
                  ? "collapse navbar-collapse justify-content-between show"
                  : "collapse navbar-collapse justify-content-between"
              }
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
                <li
                  class={splitLocation[1] === "Home" ? "nav-item active" : ""}
                >
                  <Link
                    activeClassName="active"
                    class="nav-link"
                    aria-current="page"
                    to="/"
                  >
                    Home
                  </Link>
                </li>

                <li
                  class={splitLocation[1] === "Donate" ? "nav-item active" : ""}
                >
                  <Link class="nav-link" activeClassName="active" to="/Donate">
                    Donate
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* <div className="navbar navbar-expand-lg bg-dark navbar-dark justify-content-center">
    <div className="container-fluid">
      <Link to="/" className="navbar-brand justify-content-center">
      <img src={Logo} className="img-fluid" width={"100%"}></img>
      </Link>
      <button onClick={()=>handleMediaQueryChange2()} type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
        <span className="navbar-toggler-icon" />
      </button>
      <div className={show ? "collapse navbar-collapse justify-content-between show":"collapse navbar-collapse justify-content-between"}   id="navbarCollapse">
        <div className="navbar-nav ml-auto">
          <Link to="/" className="nav-item nav-link active" activeClassName="active-link"  isActive={checkActive}>Home</Link>
          <Link to="/About" className="nav-item nav-link">About</Link>
          <Link to="/Donate" className="nav-item nav-link">Donate</Link> */}
        {/* <a href="event.html" className="nav-item nav-link">Events</a> */}
        {/* <Link to="/Blogs" className="nav-item nav-link">Blog</Link> */}
        {/* <div className="nav-item dropdown">
            <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Pages</a>
            <div className="dropdown-menu">
              <a href="single.html" className="dropdown-item">Detail Page</a>
              <a href="service.html" className="dropdown-item">What We Do</a>
              <a href="team.html" className="dropdown-item">Meet The Team</a>
              <Link to="/Donate" className="dropdown-item">Donate Now</Link>
              <a href="volunteer.html" className="dropdown-item">Become A Volunteer</a>
            </div>
          </div> */}
        {/* <Link to="/Contact" className="nav-item nav-link">Contact</Link>
        </div>
      </div>
    </div>
  </div> */}

        {/* Nav Bar End */}
      </div>
    </div>
  );
};

export default Header;
