import React, { useRef, useState } from "react";
import CountryData from "../Country.json";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const DonateNow = () => {
  const premiumpaymentRef = useRef(null);
  const Premium = () => {
    premiumpaymentRef.current.onClick();
  };
  const navigate = useNavigate("");
  const [donationDetails, setDonationDetails] = useState({
    currency: "USD", // Default currency value
    amount: "",
    amount1: "",
    giving: "",
    groupType: "",
    groupName: "",
    yourPosition: "",
    groupCountry: "",
    groupAddress: "",
    groupCity: "",
    groupState: "",
    groupZipcode: "",
    tributeTo: "",
    zakatDonation: false,
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    mobile: "",
    country: "",
    town: "",
    city: "",
    zipCode: "",
    address: "",
    donation_updates_post: 0,
    donation_updates_email: 0,
    donation_updates_telephone: 0,
    donation_updates_text: 0,
  });

  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  const handleCurrencyChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCurrency(selectedValue);
  };

  const handleInputChange = (field, event) => {
    const value = event.target.value;
    setDonationDetails({ ...donationDetails, [field]: value });
  };

  const handleCheckboxChange = (field, event) => {
    const checked = event.target.checked;
    setDonationDetails({ ...donationDetails, [field]: checked });
  };
  const handleDonationUpdateChange = (event) => {
    const { name, value } = event.target;
    setDonationDetails((prevState) => ({
      ...prevState,
      [name]: parseInt(value),
    }));
  };
  const rs = [20, 50, 75, 100, 250, 500, 1000, "other"];
  const give = ["As an individual", "As a group", "In tribute"];
  const Stripe = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    var formdata = new FormData();
    formdata.append("currency", donationDetails.currency);
    formdata.append(
      "price",
      donationDetails.amount === "other"
        ? donationDetails.amount1
        : donationDetails.amount
    );
    formdata.append("giving", donationDetails.giving);
    formdata.append("gift_tribute", donationDetails.tributeTo);
    formdata.append("group_organization_type", donationDetails.groupType);
    formdata.append("group_organization_name", donationDetails.groupName);
    formdata.append("group_position", donationDetails.yourPosition);
    formdata.append("group_country", donationDetails.groupCountry);
    formdata.append("group_postcode", donationDetails.groupZipcode);
    formdata.append("group_address", donationDetails.groupAddress);
    formdata.append("group_city", donationDetails.groupCity);
    formdata.append("group_state", donationDetails.groupState);
    formdata.append("zakat_donation", donationDetails.zakatDonation);
    formdata.append("contact_title", donationDetails.title);
    formdata.append("contact_f_name", donationDetails.firstName);
    formdata.append("contact_l_name", donationDetails.lastName);
    formdata.append("contact_email", donationDetails.email);
    formdata.append("contact_telephone", donationDetails.telephone);
    formdata.append("contact_mobile", donationDetails.mobile);
    formdata.append("post", donationDetails.donation_updates_post);
    formdata.append("email", donationDetails.donation_updates_email);
    formdata.append("telephone", donationDetails.donation_updates_telephone);
    formdata.append("text", donationDetails.donation_updates_text);
    formdata.append("country", donationDetails.country);
    formdata.append("postcode", donationDetails.zipCode);
    formdata.append("address", donationDetails.address);
    formdata.append("town", donationDetails.town);
    formdata.append("city", donationDetails.city);
    formdata.append("gift_aid", 0);
    formdata.append("claim_gift_aid", 0);
    formdata.append("source", token.id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://gazaapi.dev-sh.xyz/api/payment", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          toast.success(result.message);
          navigate('/')
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="container">
      <div className="row ">
        <div className="col-md-8">
          <div className=" p-3">
            <h3>My Donation</h3>
            <h5>Please Check Your Currency.</h5>
            <select
              className="form-control mb-3 w-50"
              onChange={handleCurrencyChange}
            >
              <option value="USD">&#x24; Dollar</option>
              <option value="GBP">&#xa3; Pound</option>
              <option value="EUR">&#x20AC; Euro</option>
            </select>

            <div className="row">
              {rs.map((e) => (
                <div className="col-md-2 mb-3" key={e}>
                  {e === "other" ? (
                    <div className="d-flex">
                      <input
                        type="radio"
                        value={e}
                        checked={donationDetails.amount === e}
                        onChange={() =>
                          setDonationDetails({ ...donationDetails, amount: e })
                        }
                      />
                      &nbsp;
                      {e}&nbsp;
                      {donationDetails.amount === "other" && (
                        <input
                          type="text"
                          placeholder="Enter custom amount"
                          value={donationDetails.amount1}
                          onChange={(event) =>
                            handleInputChange("amount1", event)
                          }
                        />
                      )}
                      &nbsp;
                    </div>
                  ) : (
                    <label>
                      <input
                        type="radio"
                        value={e}
                        checked={donationDetails.amount === e}
                        onChange={() =>
                          setDonationDetails({ ...donationDetails, amount: e })
                        }
                      />{" "}
                      <i
                        className={`fas fa-${
                          selectedCurrency === "USD"
                            ? "dollar"
                            : selectedCurrency === "GBP"
                            ? "pound"
                            : selectedCurrency === "EUR"
                            ? "euro"
                            : "dollar"
                        }-sign`}
                      />{" "}
                      {e}
                    </label>
                  )}
                </div>
              ))}
            </div>
            <h3>I am giving</h3>
            {give.map((e) => (
              <div className="row mb-3 mx-2">
                <div className="d-flex">
                  <input
                    type={"radio"}
                    value={e}
                    checked={donationDetails.giving === e}
                    onChange={() =>
                      setDonationDetails({ ...donationDetails, giving: e })
                    }
                  />
                  &nbsp;
                  {e}&nbsp;
                </div>
              </div>
            ))}
            {donationDetails.giving === "As a group" ? (
              <>
                <h3>If giving as a group:</h3>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="">Organisation type</label>
                    <select
                      className="form-control"
                      value={donationDetails.groupType}
                      onChange={(event) =>
                        handleInputChange("groupType", event)
                      }
                    >
                      <option value="" disabled>
                        Select...
                      </option>
                      <option value="Corporate">Company</option>
                      <option value="Faith Group - Muslim">
                        Muslim Faith Group
                      </option>
                      <option value="Faith Group - Christian">
                        Christian Faith Group
                      </option>
                      <option value="Faith Group - Other">
                        All Other Faith Groups
                      </option>
                      <option value="Local Group">Organisation</option>
                      <option value="Trade Union">Trade Union</option>
                      <option value="School">School</option>
                      <option value="College">College</option>
                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="">
                      Name of group / organisation / company*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={donationDetails.groupName}
                      onChange={(event) =>
                        handleInputChange("groupName", event)
                      }
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="">Your position in the group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={donationDetails.yourPosition}
                      onChange={(event) =>
                        handleInputChange("yourPosition", event)
                      }
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="">Group country*</label>
                    <select
                      className="form-control"
                      value={donationDetails.groupCountry}
                      onChange={(event) =>
                        handleInputChange("groupCountry", event)
                      }
                    >
                      {CountryData.map((e) => (
                        <option value={e.name} key={e.name}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="">Group address*</label>
                    <input
                      type="text"
                      className="form-control"
                      value={donationDetails.groupAddress}
                      onChange={(event) =>
                        handleInputChange("groupAddress", event)
                      }
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="">Group city*</label>
                    <input
                      type="text"
                      className="form-control"
                      value={donationDetails.groupCity}
                      onChange={(event) =>
                        handleInputChange("groupCity", event)
                      }
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="">Group state</label>
                    <input
                      type="text"
                      className="form-control"
                      value={donationDetails.groupState}
                      onChange={(event) =>
                        handleInputChange("groupState", event)
                      }
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="">Group zipcode*</label>
                    <input
                      type="text"
                      className="form-control"
                      value={donationDetails.groupZipcode}
                      onChange={(event) =>
                        handleInputChange("groupZipcode", event)
                      }
                    />
                  </div>
                </div>
              </>
            ) : donationDetails.giving === "In tribute" ? (
              <>
                <h3>If giving in tribute:</h3>
                <div className="col-md-12 mb-3">
                  <label htmlFor="">My gift is given in tribute to</label>
                  <textarea
                    className="form-control"
                    name="tributeTo"
                    id="tributeTo"
                    cols="80"
                    rows="5"
                    value={donationDetails.tributeTo}
                    onChange={(event) => handleInputChange("tributeTo", event)}
                  ></textarea>
                  <input
                    type="checkbox"
                    style={{marginRight:'10px'}}
                    value={"Please treat donation as a Zakat donation"}
                    checked={donationDetails.zakatDonation}
                    onChange={(event) =>
                      handleCheckboxChange("zakatDonation", event)
                    }
                  />
                 Please treat donation as a Zakat donation
                </div>
              </>
            ) : (
              <>
                <input
                  type="checkbox"
                  value={"Please treat donation as a Zakat donation"}
                  style={{marginRight:'10px'}}
                  checked={donationDetails.zakatDonation}
                  onChange={(event) =>
                    handleCheckboxChange("zakatDonation", event)
                  }
                />
                Please treat donation as a Zakat donation
              </>
            )}
            <hr />
            <h3>Contact Details</h3>
            <div className="row">
              <div className="col-md-2 mb-3">
                <label htmlFor="title">Title</label>
                <select
                  className="form-control"
                  id="title"
                  value={donationDetails.title}
                  onChange={(e) => handleInputChange("title", e)}
                >
                  <option value="">Select</option>
                  <option value="Dr">Dr</option>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Professor">Professor</option>
                  <option value="Reverend">Reverend</option>
                </select>
              </div>
              <div className="col-md-5 mb-3">
                <label htmlFor="firstName">First name*</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  value={donationDetails.firstName}
                  onChange={(e) => handleInputChange("firstName", e)}
                />
              </div>
              <div className="col-md-5 mb-3">
                <label htmlFor="lastName">Last name*</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  value={donationDetails.lastName}
                  onChange={(e) => handleInputChange("lastName", e)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={donationDetails.email}
                  onChange={(e) => handleInputChange("email", e)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="telephone">Telephone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="telephone"
                  value={donationDetails.telephone}
                  onChange={(e) => handleInputChange("telephone", e)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="mobile">Mobile</label>
                <input
                  type="number"
                  className="form-control"
                  id="mobile"
                  value={donationDetails.mobile}
                  onChange={(e) => handleInputChange("mobile", e)}
                />
              </div>
            </div>
            <hr/>
            <div className="row px-2">
              <div className="table-wrapper">
                <table cellSpacing={4} cellPadding={4} border={0}>
                  <tbody>
                    <tr>
                      <td />
                      <td>Yes</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="donation_updates_post_y">Post</label>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          name="donation_updates_post"
                          id="donation_updates_post_y"
                          value="1"
                          checked={donationDetails.donation_updates_post === 1}
                          onChange={handleDonationUpdateChange}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          name="donation_updates_post"
                          id="donation_updates_post_n"
                          value="0"
                          checked={donationDetails.donation_updates_post === 0}
                          onChange={handleDonationUpdateChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="donation_updates_email_y">Email</label>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          name="donation_updates_email"
                          id="donation_updates_email_y"
                          value="1"
                          checked={donationDetails.donation_updates_email === 1}
                          onChange={handleDonationUpdateChange}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          name="donation_updates_email"
                          id="donation_updates_email_n"
                          value="0"
                          checked={donationDetails.donation_updates_email === 0}
                          onChange={handleDonationUpdateChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="donation_updates_telephone_y">
                          Telephone
                        </label>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          name="donation_updates_telephone"
                          id="donation_updates_telephone_y"
                          value="1"
                          checked={
                            donationDetails.donation_updates_telephone === 1
                          }
                          onChange={handleDonationUpdateChange}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          name="donation_updates_telephone"
                          id="donation_updates_telephone_n"
                          value="0"
                          checked={
                            donationDetails.donation_updates_telephone === 0
                          }
                          onChange={handleDonationUpdateChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="donation_updates_text_y">Text</label>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          name="donation_updates_text"
                          id="donation_updates_text_y"
                          value="1"
                          checked={donationDetails.donation_updates_text === 1}
                          onChange={handleDonationUpdateChange}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          name="donation_updates_text"
                          id="donation_updates_text_n"
                          value="0"
                          checked={donationDetails.donation_updates_text === 0}
                          onChange={handleDonationUpdateChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="table-wrapper">
                <table cellSpacing={4} cellPadding={4} border={0}>
                  <tbody>
                    {/* Add your table with radio buttons and labels here */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row px-2">
              <p>
                <i>
                  If you are currently subscribed to our email list, you can
                  unsubscribe by clicking the link in the footer of the email.
                </i>
              </p>
              <div className="col-md-6 mb-3">
                <label htmlFor="country">Country*</label>
                <select
                  type="text"
                  className="form-control"
                  id="country"
                  value={donationDetails.country}
                  onChange={(e) => handleInputChange("country", e)}
                >
                  {CountryData.map((e) => (
                    <option value={e.name} key={e.name}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="town">Town*</label>
                <input
                  type="text"
                  className="form-control"
                  id="town"
                  value={donationDetails.town}
                  onChange={(e) => handleInputChange("town", e)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="city">City*</label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  value={donationDetails.city}
                  onChange={(e) => handleInputChange("city", e)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="zipCode">Zip Code*</label>
                <input
                  type="text"
                  className="form-control"
                  id="zipCode"
                  value={donationDetails.zipCode}
                  onChange={(e) => handleInputChange("zipCode", e)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="address">Address*</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  value={donationDetails.address}
                  onChange={(e) => handleInputChange("address", e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn btn-primary mx-2"
        onClick={() => {
          Premium();
        }}
      >
        {" "}
        Submit
      </button>
      {console.log(donationDetails.currency)}
      <StripeCheckout
        name="Show"
        // image={img1}
        ref={premiumpaymentRef}
        currency={selectedCurrency}
        className="d-none"
        amount={donationDetails.amount * 100|| donationDetails.amount1 * 100}
        token={Stripe}
        stripeKey="pk_test_51HvHoVBGDx2Lb6IFoAUFCZG7LjSCigVrcHPAlXO45x02BCK4sLaWAzBTCjOh8xN2O2ahFw4a69n5SY7GW7RbJsie005oQBdm0K"
      />
    </div>
  );
};

export default DonateNow;
