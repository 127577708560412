import React from "react";
import hospital from '../../img/hospital.webp'
import hospital2 from '../../img/hospital2.jpg'
const DonateBanner = () => {
  return (
    <div style={{ paddingTop: 145 }}>
      <div className="container">
        <h1 style={{ color: "#FDBE33 " }}>EMERGENCY APPEAL</h1>
        <div className="row">
          <div className="col-md-8">
            <img src={hospital} className="w-100" alt="" />
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className="card p-3 text-white"
                  style={{ background: "#FDBE33 " }}
                >
                  <h4 className="text-white">THE MAP PROMISE</h4>
                  <h5 className="text-white">
                    We don’t share details with other charities.
                  </h5>
                  <p>
                    We promise to use your donation wherever the need is
                    greatest. We won’t pass your name on or contact you unless
                    you want to hear from us.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card  ">
                  <img src={hospital2} alt="asda" />
                  <div className="p-3">
                    <h5 style={{ color: "#FDBE33 " }}>
                      DO MORE WITH A REGULAR GIFT
                    </h5>

                    <p>Set up a regular gift and help us do more.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-8">
            <div className="p-1">
              <h5 className="text-dark">
                Medical Aid for Palestinians is responding to the current
                emergency in Gaza. <b>Please give an urgent donation today.</b>
              </h5>
              <p className="text-dark">
                Our team in Gaza are working to distribute the remaining medical
                supplies that we were able to procure inside Gaza. Due to
                limited storage capacity in some of the hospitals, the
                distributions are still ongoing. Gaza team are also working to
                procure and distribute other critical supplies like mattresses,
                blankets, and hygiene kits. To date, our Gaza team has managed
                to provide US$1.8 million worth of essential supplies in
                addition to our pre-positioned stock of US$570,000.
              </p>
              <p className="text-dark">
                <b>
                  The scale of this emergency is unprecedented, and we need your
                  help urgently to do more.
                </b>
              </p>
              <p className="text-dark">
                The scale of this emergency is unprecedented, and we need your
                help urgently to do more.
              </p>
              <p className="text-dark">
                Please give a gift today and help us ensure that Palestinian
                health workers have the medical resources they need, as soon as
                possible.
                <b>Thank you.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonateBanner;
