import logo from './logo.svg';
import './App.css';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from '../src/pages/Home';
// import About from './pages/About';
// import Contact from './pages/Contact';
import Donate from './pages/Doante';
// import Blogs from './pages/Blogs';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          {/* <Route path="/About" element={<About />} /> */}
          {/* <Route path="/Contact" element={<Contact />} /> */}
          <Route path="/Donate" element={<Donate />} />
          {/* <Route path="/Blogs" element={<Blogs />} /> */}



        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
